.wrapper {
  position: relative;
  padding: 60px 0 100px;
  overflow: hidden;
}

.wrapper::after {
  content: "";
  position: absolute;
  bottom: -30%;
  right: 0;
  width: 30%;
  height: 180%;
  background: var(--red);
  transform: rotate(30deg);
  z-index: -1;
}

.scaledContainer {
  z-index: -1;
  padding: 0 5%;
}

.serviceContainer,
.productContainer,
.cardsContainer {
  padding: 0 5%;
}

.serviceContainer {
  position: relative;
}

.primaryContent,
.secondaryContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.primaryContent {
  width: 50%;
}

.secondaryContent {
  width: 33%;
  margin-top: -40px;
  margin-left: auto;
}

.secondaryContent p {
  z-index: 5;
}

.backgroundImage {
  margin: auto;
  margin-top: -40%;
  height: auto;
}

.scrollingImage {
  position: absolute;
  top: 60%;
  translate: 0 -50%;
  left: 0;
  height: 100%;
  width: 100%;
}

.scrollingImage img {
  max-width: 80%;
  height: auto;
}

.productContainer {
  position: relative;
  display: flex;
  width: 90%;
  margin: auto;
  margin-bottom: 140px;
  margin-top: -100px;
  z-index: 1;
}

.productImage {
  width: 55%;
  flex-shrink: 0;
  height: auto;
}

.productInfo {
  background-color: var(--white);
  padding: 80px;
  margin: 80px 0;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-start;
  justify-content: center;
  box-shadow: var(--shadow);
}

.productTitle {
  border-left: 10px solid var(--red);
  font-size: clamp(60px, 5vw, 140px);
  color: var(--red);
  padding-left: 40px;
}

.cardsContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.cardsHeading {
  font-size: 60px;
}

.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 60px;
}

.card {
  background-color: var(--white);
  padding: 10% 10% 15%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  text-align: start;
  box-shadow: var(--shadow);
}

.cardNumber {
  background-color: var(--red);
  color: var(--white);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  aspect-ratio: 1 / 1;
  width: clamp(60px, 40%, 140px);
  font-weight: bold;
  font-size: 50px;
  margin-bottom: 20px;
}

.cardHeading {
  font-size: 30px;
}

.cardSubheading {
  font-size: 20px;
  font-weight: bold;
  color: var(--red);
}

.star {
  background: var(--red);
  clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
  display: inline-block;
  height: 20px;
  width: 20px;
}

@media screen and (max-width: 1024px) {
  .wrapper {
    padding-top: 40px;
  }

  .contentContainer {
    position: relative;
    z-index: 1;
    display: flex;
    gap: 5%;
  }

  .primaryContent,
  .secondaryContent {
    width: 50%;
    padding: 5%;
    margin-top: 0;
    background-color: var(--white);
    box-shadow: var(--shadow);
  }

  .scrollingImage {
    top: 60%;
  }

  .productContainer {
    flex-direction: column;
    box-shadow: var(--shadow);
    padding: 0;
  }

  .productImage {
    width: 100%;
    max-height: 40vh;
    object-fit: cover;
  }

  .productInfo {
    margin: 0;
    box-shadow: unset;
  }

  .cards {
    grid-template-columns: repeat(2, 1fr);
    gap: 5%;
  }

  .card {
    padding: 40px 30px;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    padding-top: 20px;
  }

  .wrapper::after {
    width: 70%;
    transform: rotate(10deg);
  }

  .contentContainer {
    flex-direction: column;
    gap: 40px;
  }

  .primaryContent,
  .secondaryContent {
    width: 100%;
  }

  .scrollingImage {
    top: 66%;
  }

  .productInfo {
    padding: 40px 20px;
  }

  .cards {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .card {
    padding: 40px 20px;
    gap: 16px;
  }

  .cardNumber {
    width: 80px;
  }
}
